<template>
  <div class="garmentPO-list">
    <div class="page-info">
      <div class="page-title">
        <v-icon color="secondary">mdi-clipboard-multiple-outline</v-icon>
        <p class="title-1">Garment PO List</p>
      </div>

      <v-breadcrumbs :items="breadcrumbs">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
    </div>

    <v-card outlined flat rounded="xl" class="pa-8">
      <input type="file" ref="selectFile" @change="postFile" style="display: none" />
      <v-col class="text-right mb-4">
        <v-btn :elevation="0" color="secondary" :loading="loading" @click="$refs.selectFile.click()">
          Convert Raw Data File
        </v-btn>
      </v-col>

      <v-data-table
        hide-default-footer
        disable-sort
        :headers="headers"
        :items="filteredItem"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        @page-count="pageCount = $event"
        :no-data-text="$t('common.text.noData')"
        :no-results-text="$t('common.text.noMatchingResult')">
        <template v-slot:[`item.garmentPOTitle`]="{ item }">
          <div class="d-flex fh fw align-center pointer" @click="rowClickHandler(item)">{{ item.garmentPOTitle }}</div>
        </template>
        <template v-slot:[`item.sysRegDt`]="{ item }" class="pointer" @click="rowClickHandler(item)">
          <div class="d-flex fh fw align-center pointer" @click="rowClickHandler(item)">{{ item.sysRegDt }}</div>
        </template>
        <template v-slot:[`item.downloadData`]="{ item }">
          <v-btn outlined color="secondary" :href="`${$s3}${item.downloadDataFilePath}`">
            Download Raw Data File
          </v-btn>
        </template>
        <template v-slot:[`item.downloadExcel`]="{ item }">
          <v-btn
            outlined
            color="secondary"
            :href="`/api/garmentPO/excelDownload/${$store.state.currentRoute.params.cmpyUUID}/${item.garmentPOUUID}?token=${$store.state.user.token}`">
            Download Excel File
          </v-btn>
        </template>
      </v-data-table>

      <div class="text-center pt-8" v-if="pageCount > 0">
        <v-pagination v-model="page" :length="pageCount" color="secondary" :total-visible="$pagePV"></v-pagination>
      </div>
    </v-card>
  </div>
</template>

<script>
import codeHandler from "@/utils/codeHandler";

export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: "Purchase Order",
          disabled: true,
        },
        {
          text: "Garment PO",
          disabled: true,
        },
      ],
      headers: [
        { text: "TITLE", value: "garmentPOTitle" },
        { text: "DATE", value: "sysRegDt" },
        { text: "Raw Data File", value: "downloadData", width: 70 },
        { text: "Converted File", value: "downloadExcel", width: 70 },
      ],
      items: [],
      page: 1,
      itemsPerPage: 10,
      pageCount: 0,
      selectFile: null,
      loading: false,
    };
  },
  created() {
    this.$http
      .get("/garmentPO/POList", {
        data: {
          cmpyUUID: this.$store.state.currentRoute.params.cmpyUUID,
        },
      })
      .then(res => {
        if (res.data.status === 200) {
          this.items = res.data.data;
        } else codeHandler(res.data);
      });

    // this.items = [
    //   {
    //     garmentPOUUID: "GPO0001",
    //     garmentPOTitle: "Garment PO 0001",
    //     sysRegDt: "2022-02-27 12:25:40.0",
    //   },
    // ];
  },
  computed: {
    filteredItem() {
      return this.items.map(i => ({
        garmentPOUUID: i.garment_PO_UUID,
        garmentPOTitle: i.fileName,
        downloadDataFilePath: i.filePath,
        sysRegDt: this.$m(i.sysRegDt).format("yyyy-MM-DD h:mm:ss a"),
        downloadData: true,
        downloadExcel: true,
      }));
    },
  },
  methods: {
    postFile() {
      if (this.$refs.selectFile.files.length < 1 || !this.$refs.selectFile.files[0])
        return this.$toast.info("선택된 파일이 없습니다.");

      let form = new FormData();
      form.append("file", this.$refs.selectFile.files[0]); // api file name
      form.append("cmpyUUID", this.$store.state.currentRoute.params.cmpyUUID);

      this.loading = true;

      this.$http
        .post("/garmentPO/insert", form, {
          timeout: 60 * 1000,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(res => {
          if (res.data.status === 200) {
            this.$toast.info("업로드에 성공하였습니다.");
            this.loading = false;
            this.items.unshift(res.data.data);
          } else codeHandler(res.data);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    rowClickHandler({ garmentPOUUID }) {
      if (!garmentPOUUID) return;
      this.$router.push({ name: "garmentPODetail", params: { garmentPOUUID } });
    },
  },
};
</script>

<style lang="scss">
.garmentPO-list {
  th {
    color: black !important;
    vertical-align: inherit !important;
  }
}
</style>
